@use './styles/_colors.scss';

.errorPage {
    h1 {
        color: colors.$accent-primary;
        font-weight: 900;
        text-align: center;
        font-size: 100px;
    }

    .errorContainer {
        color: colors.$accent-secondary;
        text-align: center;

        position: absolute;
        top: 20%;
        left: 0;
        right: 0;
        margin: auto;

        p {
            margin: 0;
        }

        h1 {
            margin: 20px 0;
        }
    }
}

footer {
    position: absolute;
}
@font-face {
    font-family: 'Switzer-Variable';
    src: url('/static/fonts/Switzer-Variable.woff2') format('woff2'),
         url('/static/fonts/Switzer-Variable.woff') format('woff'),
         url('/static/fonts/Switzer-Variable.ttf') format('truetype');
         font-weight: 100 900;
         font-display: swap;
         font-style: normal;
}

@font-face {
    font-family: 'Switzer-VariableItalic';
    src: url('/static/fonts/Switzer-VariableItalic.woff2') format('woff2'),
         url('/static/fonts/Switzer-VariableItalic.woff') format('woff'),
         url('/static/fonts/Switzer-VariableItalic.ttf') format('truetype');
         font-weight: 100 900;
         font-display: swap;
         font-style: italic;
}
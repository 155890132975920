@use './styles/_colors.scss';

header {
    background-color: colors.$background-primary;
    position: sticky;
    width: 100%;
    top: 0px;
    z-index: 1002;
    // overflow: hidden;

    div.header-top {
        background-color: colors.$color-secondary;
        height: 20px;
    }

    div.header-main {
        position: relative;
        max-width: 1200px;
        height: 50px;
        margin: 0 auto;

        h2, p {
            color: colors.$color-secondary;
            margin: 0;

            a {
                text-decoration: inherit;
                color: inherit;
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        
        h2 {
            font-weight: 500;
            line-height: 32px;
    
            position: absolute;
            top: 9px;
            left: 20px;
        }
        
        nav {
            position: absolute;
            right: 20px;
            top: 5px;
    
            p {
                height: 20px;
                margin: 5px;
                padding: 5px;
                display: inline-block;
    
                :hover {
                    text-decoration: underline;
                }
            }
        }
    
        .query-container {
            position: absolute !important;
            left: 50%;
            top: 7px;
            max-width: 400px;
            width: 400px;
            transform: translateX(-50%);
    
            .query-input {
                color: colors.$accent-secondary;
                background-color: colors.$color-secondary;
                border: 1px solid colors.$color-secondary;
                border-radius: 5px;
    
                width: 100%;
                height: 36px;
                padding: 2px 10px;
                outline: none;
                box-sizing: border-box;
            }
    
            .query-typeahead { color: colors.$accent-primary; }
    
            .query-listbox {
                color: colors.$accent-secondary;
                background-color: colors.$color-secondary;
                border: 0px;
                border-radius: 0 0 5px 5px;
                box-shadow: 0px 0px 8px -6px rgba(0,0,0,0.75);
    
                position: absolute;
                top: 43px;
                width: 400px;
                padding: 5px;
                box-sizing: border-box;
            }
    
            .query-item {
                border: 1px solid colors.$color-secondary;
                border-radius: 5px;
                padding: 10px 4px;
                
                transition-duration: 150ms;
    
                p {
                    color: colors.$color-primary;
                    cursor: pointer;
        
                    span {
                        color: colors.$accent-secondary;
                    }
                }
            }

            .query-item-highlight {
                border: 1px solid colors.$accent-primary;
                border-radius: 5px;
                padding: 10px 4px;

                p {
                    color: colors.$color-primary;
                    cursor: pointer;
        
                    span {
                        color: colors.$accent-secondary;
                    }
                }
            }
            
        }
    
        @media only screen and (max-width: 600px) {
            nav {
                background: colors.$color-secondary;

                position: absolute;
                top: 50px;
                right: 0;
                width: 100%;
        
                p {
                    color: colors.$accent-secondary;

                    height: 20px;
                    margin: 5px;
                    padding: 5px;
                    display: block;
        
                    :hover {
                        text-decoration: underline;
                    }
                }
            }
        }   
    }
}
@use 'styles/_colors.scss';
@use 'styles/_fonts.scss';

html {
    font-family: 'Switzer-Variable';
    color: colors.$color-primary;
}

html, body {
    height: 100%;
}

#root {
    width: 100%;
    min-height: 100%;
}

main {
    height: 100%;
}
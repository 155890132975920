@use './styles/_colors.scss';

footer {
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 500px;
    margin: 20px auto;

    p, a {
        color: colors.$accent-primary;
        text-align: center;
        font-size: 12px;
    
        margin: 0;
    }

    hr {
        border: 1px solid colors.$accent-primary;
        width: 50px;
    }
}

.docs { 
    height: 2000px;
}